/* @import '../../assets/Stint_Ultra_expanded'; */
/* @import '../../assets/Stint_Ultra_expanded_italic'; */
/*importar fuente StintUltraExpanded-Regular */
@import url("https://fonts.googleapis.com/css?family=Stint+Ultra+Expanded:400,700,900");

.selected {
  background-color: #e6e6e6;
}

.logout_button {
  background: #ffa600;
  border-radius: 30px;
  border: none;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  color: var(--greeny);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 10px 10px;
  padding: 15px;
  /*   position: absolute;
  right: 10px; */
  text-transform: uppercase;
  transition: all 0.4s;
  width: 120px;
}

.logout_button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.logout_button:active {
  transform: scale(0.95);
  transition: 0.3s all;
}

.MuiPaper-root.MuiDrawer-paperAnchorLeft {
  background: var(--greeny);
} 

.menu_phi {
  font-family: "Stint Ultra Expanded", sans-serif;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 4px;
  color: white;
}

.menu_byMarketOne {
  font-size: 21px;
  color: #9a999e;
  font-weight: bolder;
  font-style: italic;
  margin-left: 15px;
}

.menu_companyLogo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.menu_companyLogo_container img {
  width: 40%;
  border-radius: 50%;
  margin: 20px 0;
  object-fit: cover;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
}

.menu_header_container {
  position: fixed;
  display: flex;
}
