.layout_container {
  display: flex;
  align-items: baseline;
}

.layout_menuContainer {
  width: 50px;
  z-index: 10;
}

.layout_outletContainer {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  top: 90px;
  margin-top: 40px;
}
