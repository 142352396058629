.cc_step1_container {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.cc_step_container {
  align-items: flex-start;
  background-color: "white";
  border-radius: 5px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
  color: var(--blue);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  min-width: 300px;
  padding: 10px 25px;
}

.cc_step_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 290px;
  width: 100%;
}

.cc_step_checkbox {
  display: flex;
}

.cc_step_form_select {
  margin-bottom: 10px;
}


.cc_step_form_input {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  margin: 5px 0 5px 0;
  padding: 10px 15px;
  width: 100%;
}

.cc_step_form_input_empty {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--magenta);
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  margin: 5px 0 10px 0;
  padding: 10px 15px;
  width: 100%;
}

.cc_step_form_input_empty:focus {
  appearance: none;
  border: 1px solid var(--magenta);
  outline: 1px solid var(--magenta);
}

.cc_step_form_input:focus {
  -webkit-appearance: none;
  appearance: none;
  outline: 2px solid var(--blue-greeny);
  /*  border: 2px solid var(--blue-greeny); */
}

.cc_select {
  margin: 0;
  width: 100%;
}

.cc_checkbox_container {
  display: flex;
  margin: 5px 0 10px 0;
}

.cc_checkbox {
  margin-right: 10px;
}

.cc_subtitle {
  color: var(--light-blue);
  font-size: 18px;
}

.cc_step_form_label {
  color: var(--blue);
  font-weight: 500;
}

.cc_step_form_label span {
  color: var(--magenta);
  font-weight: 700;
}

.cc_add_target_box {
  display: flex;
  align-items: flex-start;
  margin: 0px;
  width: 100%;
  justify-content: space-between;
  margin: 5px 0 10px 0;
}

.cc_expense_target_select {
  width: 100%;
}

.cc_add_button {
  background: var(--light-blue);
  border-radius: 50%;
  border: 1px solid var(--light-blue);
  color: white;
  font-size: 30px;
  height: 38px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s;
  vertical-align: middle;
  width: 38px;
  min-width: 38px;
  margin: 0 0 0 10px;
}

.cc_add_button:hover {
  background: white;
  cursor: pointer;
  font-weight: bold;
  color: var(--light-blue);
}

.cc_add_button:active {
  transform: scale(0.95);
  transition: 0.3s all;
}

.cc_addTarget_column {
  margin-bottom: 15px;
}

.cc_newTarget_checkbox {
  margin-left: 0;
  margin-right: 10px;
}

.cc_newTargetError {
  color: red;
  font-size: 14px;
  margin: 3px 0;
}

/* Step 2: Filter Styles */
.cc_filters_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 10px 0;
}

.cc_filter_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 5px;
  vertical-align: top;
}

.cc_filter_column_1,
.cc_filter_column_2,
.cc_filter_column_3,
.cc_filter_column_4,
.cc_filter_column_5,
.cc_filter_column_6 {
  text-align: center;
}

.cc_filter_column_1 {
  width: 3%;
}

.cc_filter_column_2 {
  width: 14%;
}

.cc_filter_column_3 {
  width: 25%;
}

.cc_filter_column_4 {
  width: 35%;
}

.cc_filter_column_5 {
  width: 15%;
}

.cc_filter_column_6 {
  width: 5%;
}

.cc_info_filters_error {
  font-weight: 700;
  font-size: 18px;
  color: var(--magenta);
  margin: 0;
  width: 100%;
  text-align: center;
}

.cc_info_filters_success {
  font-weight: 700;
  font-size: 18px;
  color: var(--blue-greeny);
  margin: 0;
}

.cc_info_filters_info {
  font-weight: 700;
  font-size: 18px;
  color: var(--yellow);
  margin: 0;
}

.cc_info_filters_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 5px;
}

.cc_filter_condition {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cc_condition_values {
  color: var(--blue);
  font-weight: 800;
  vertical-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Step 3: Union styles */
.cc_union_column_1 {
  text-align: center;
  width: 5%;
}

.cc_union_expenses_column {
  text-align: center;
  width: 40%;
}

.cc_union_file_column {
  text-align: center;
  width: 40%;
}

.cc_union_column_4 {
  width: 10%;
  text-align: center;
}

.cc_union_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
}

/* Step 4: External styles */
.cc_external_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.cc_external_column_file {
  text-align: center;
  width: 100%;
}

.cc_external_column_1 {
  text-align: center;
  width: 5%;
}

.cc_external_column_2 {
  text-align: center;
  width: 39%;
}

.cc_external_column_3 {
  text-align: center;
  width: 39%;
}

.cc_external_column_4 {
  text-align: center;
  width: 11%;
}
/*

.MuiPaper-root {
  color: white;
}*/

.MuiAutocomplete-paper {
  background-color: white;
  color: black;
}

