.css-6hp17o-MuiList-root-MuiMenu-list{
    color: var(--dark-grey) !important;
  }

  .es_header_column {
      background-color: var(--blue-greeny);
      border: 1px solid var(--blue-greeny);
      color: white;
      height: 40px;
    }


