.css-1pkd666 .SnackbarItem-variantSuccess {
    background-color: var(--blue-greeny);
}

.css-1pkd666 .SnackbarItem-variantError {
    background-color: var(--magenta);
}

.css-1pkd666 .SnackbarItem-variantWarning {
    background-color: var(--yellow);
    color: var(--blue);
    font-weight: 700;
}

.css-1pkd666 .SnackbarItem-variantInfo {
    background-color: var(--violet);
}

.tooltipContainerStyles {
    display: flex; 
    flex-direction: column;
    align-items: center;
}

.tooltipLabel {
    margin-bottom: 8px;
}

.buttonTooltip{
border-radius: 5px;
background-color: var(--very-light-blue);
border: none;
height: 30px;
color: var(--greeny);
font-weight: 600;
cursor: pointer;
width: 95%;
}

/*Esta linea es para que el Draggable multi select funcione correctamente*/
.css-1p3m7a8-multiValue {
    z-index: 10000;
}
.css-eo85ql-menu {
    z-index: 10001;
}