:root {
  --blue: #0D3940;
  --dark-blue: #172D40;
  --light-blue: #396992;
  --very-light-blue: #9EAFB2;
  --very-very-light-blue: #b8cdd1;
  --light-blue-2: #3781f1;
  --blue-greeny: #346269;
  --greeny: #0d3940;
  --magenta: #E0438F;
  --light-magenta: #fb6e92;
  --pink: #bb3d5c;
  --very-light-magenta: #faa9bd;
  --dark-grey: #4d4c50;
  --very-dark-grey: #3b3b3b;
  --light-grey: #9a999e;
  --very-light-grey: #d8d6d6;
  --very-very-light-grey: #ebebeb;
  --cream: #0d394014;
  --green: #115631;
  --light-green: #81f368;
  --yellow: #E0CA16;
  --light-yellow: #ffc862;
  --orange: #ff8411;
  --light-red: #ffe6e6;
  --red: #a12e2c;
  --violet: #4e4e68;
  --light-violet: #8f8fab;
  --very-light-violet: #D8D1E8;
  --light-blue-2: #3781f1;
  --dark-blue-2: #14446d;
  --violet-2: #ad72b3;
  --green-2: #32b415;
  --title: 24px;
  --subtitle: 18px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
