.verification-container {
    align-items: center;
    background-color: #172d40;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
  }
  
  .verification_form {
    align-items: center;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 35%;
    min-width: 400px;
  }
  
  .verification_form_box {
    align-items: center;
    color: black;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 450px;
  }
  
  .verification_description {
    text-align: justify;
    text-justify: inter-word;
   
    width: 70%;
  }
  
  .verification_logo-container {
    margin: 15px 15px;
  }
  
  .verification_logo {
    margin-top: 20px;
    width: 120px;
  }
  
  .verification_title  {
    color: #172d40;
  }
  
  .verification_button_confirm,
  .verification_button_cancel {
    align-items: center;
    border-radius: 30px;
    border: none;
    color: white;
    display: flex;
    font-size: 14px;
    font-weight: 100;
    height: 45px;
    justify-content: center;
    letter-spacing: 2px;
    margin: 10px 0px;
    padding: 15px;
    text-transform: uppercase;
    transition: all 0.4s;
    width: 150px;
  }
  
  .verification_password_container {
    position: relative;
    width: 280px;
  }
  
  .verification_password_icon {
    bottom: 7px;
    position: absolute;
    right: 10px;
    top: 7px;
  }
  
  .verification_password_icon:hover {
    cursor: pointer;
  }
  
  .verification_password_icon:active {
    cursor: pointer;
  }
  
  .verification_form_input {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 1px solid grey;
    box-sizing: border-box;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 10px 15px;
    width: 100%;
  }
  
  .verification_button_confirm:hover,
  .verification_button_cancel:hover {
    background: white;
    cursor: pointer;
    font-weight: bold; 
  }
  
  .verification_button_confirm:active,
  .verification_button_cancel:active {
    transform: scale(0.95);
  }
  
  .verification_button_confirm {
    background: #172d40;
  }
  
  .verification_button_cancel {
    background: #4d4c50;
  }
  
  .verification_button_confirm:hover {
    border: 2px solid #172d40;
    color: #172d40;
  }
  
  .verification_button_cancel:hover {
    border: 2px solid #4d4c50;
    color: #4d4c50;
  }
  
  .verification_resend {
    color: #4d4c50;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    text-decoration: underline;
  }
  
  .verification_resend:hover {
    cursor: pointer;
    text-decoration: none;
  }
  
  .verification_errormsg,
  .verification_successmsg {
    font-size: 14px;
    text-align: center;
    width: 400px;
    padding-top: 3px;
    margin: 0;
    font-weight: bold;
  }
  
  .verification_errormsg {
    color: #A12E2C;
  }
  .verification_successmsg {
    color: #115631;    
  }
  
  